<script>
export default {
    name: "Order"
}
</script>

<template>
    <div class="w-[calc(100%_+_32px)] -ml-4">
        <div class="flex items-center gap-[40px] py-[20px] px-[16px] relative">
            <router-link to="/profile" class="rounded-xl bg-[#C5C3DC33] w-[40px] h-[40px] flex items-center justify-center absolute left-[16px]">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path class="dark:stroke-dark-main" d="M14 7L9 12L14 17" stroke="#131313" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </router-link>
            <h3 class="font-gilroy dark:text-dark-main font-bold text-[20px] leading-[24px] w-full text-center">
                История заказов
            </h3>
        </div>
        <div class="bg-light-overlay dark:bg-transparent rounded-3xl p-[16px] mb-[16px]">
            <div class="p-[16px] rounded-3xl bg-light-bg dark:bg-transparent block mb-[16px]">
                <div class="flex gap-[8px] w-full pb-[15px]">
                    <div class="p-[5px] relative rounded-[8px] bg-white w-[65px] h-[70px] flex-shrink-0">
                        <img src="@/assets/images/product_img.png" alt="">
                        <span class="absolute left-0 top-0 flex items-center justify-center font-bold font-gilroy text-xs leading-[14px] text-white rounded-full bg-accent w-[23px] h-[23px]">
                            1
                        </span>
                    </div>
                    <div class="w-full">
                        <div class="flex justify-between mb-[14px]">
                            <h4 class="text-black dark:text-dark-main font-bold text-base leading-[20px] font-gilroy">
                                Заказ №701
                            </h4>
                            <h4 class="font-medium font-gilroy text-[12px] leading-[14px] px-[8px] py-[4px] rounded-[20px] bg-light-gray-80 dark:bg-dark-gray-80 text-white dark:text-dark-overlay">
                                Выдан
                            </h4>
                        </div>
                        <h6 class="font-gilroy font-bold text-xs leading-[14px] text-black dark:text-dark-main mb-[4px]">
                            Доставка 28 июля
                        </h6>
                        <p class="font-gilroy font-bold text-xs leading-[14px] text-description">
                            просп. Суюнбая 663, Алматы, Казахстан
                        </p>
                    </div>
                </div>
                <div class="border-t dark:border-t-light-gray-20 flex justify-between items-center pt-[12px]">
                    <router-link to="/order/31341234" class="text-accent flex items-center gap-[8px]">
                        Подробнее
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path class="dark:stroke-dark-main" d="M6 11.3337L9.33333 8.00033L6 4.66699" stroke="#131313" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </router-link>
                    <div class="flex items-center gap-[7px]">
                        <h4 class="text-black dark:text-dark-main font-bold text-base leading-[20px] font-gilroy flex items-center">
                            2 343
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.8876 5.06876L20.9873 11.2326C20.9991 11.2555 21.0028 11.2814 20.9979 11.3065C20.9931 11.3316 20.9798 11.3545 20.9602 11.372L12.5887 18.9663C12.5649 18.9879 12.5332 19 12.5003 19C12.4673 19 12.4357 18.9879 12.4119 18.9663L4.04034 11.3725C4.02071 11.355 4.0075 11.3321 4.00263 11.307C3.99776 11.2819 4.0015 11.256 4.0133 11.2331L7.11299 5.06923C7.123 5.04853 7.13908 5.031 7.15933 5.01872C7.17958 5.00645 7.20314 4.99995 7.22718 5.00001H17.7724C17.7965 4.99972 17.8203 5.00606 17.8407 5.01826C17.8611 5.03046 17.8774 5.048 17.8876 5.06876Z" fill="#50AF95"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.4244 11.8653C11.4845 11.8696 11.795 11.8871 12.4877 11.8871C13.0386 11.8871 13.4298 11.8715 13.567 11.8653C15.6961 11.7767 17.2852 11.4258 17.2852 11.0057C17.2852 10.5856 15.6961 10.2352 13.567 10.1451V11.5159C13.4277 11.5254 13.0291 11.5477 12.4782 11.5477C11.817 11.5477 11.486 11.5216 11.4264 11.5164V10.1461C9.30183 10.2357 7.71617 10.5866 7.71617 11.0057C7.71617 11.4249 9.30133 11.7757 11.4264 11.8649L11.4244 11.8653ZM11.4244 10.0043V8.77772H8.45942V6.90723H16.532V8.77772H13.5675V10.0038C15.977 10.1086 17.7891 10.5605 17.7891 11.102C17.7891 11.6434 15.977 12.0948 13.5675 12.2001V16.1307H11.4249V12.1987C9.02086 12.0939 7.21183 11.6425 7.21183 11.1015C7.21183 10.5605 9.01936 10.1091 11.4249 10.0038L11.4244 10.0043Z" fill="white"/>
                            </svg>
                        </h4>
                        <h5 class="font-gilroy font-medium text-[12px] leading-[14px] text-description">
                            ~ 786 833,35 ₸
                        </h5>
                    </div>
                </div>
            </div>
            <div class="p-[16px] rounded-3xl bg-light-bg dark:bg-transparent block">
                <div class="flex gap-[8px] w-full pb-[15px]">
                    <div class="p-[5px] relative rounded-[8px] bg-white w-[65px] h-[70px] flex-shrink-0">
                        <img src="@/assets/images/product_img2.png" alt="">
                        <span class="absolute left-0 top-0 flex items-center justify-center font-bold font-gilroy text-xs leading-[14px] text-white rounded-full bg-accent w-[23px] h-[23px]">
                            5
                        </span>
                    </div>
                    <div class="w-full">
                        <div class="flex justify-between mb-[14px]">
                            <h4 class="text-black dark:text-dark-main font-bold text-base leading-[20px] font-gilroy">
                                Заказ №700
                            </h4>
                            <h4 class="font-medium font-gilroy text-[12px] leading-[14px] px-[8px] py-[4px] rounded-[20px] bg-light-gray-80 dark:bg-dark-gray-80 text-white dark:text-dark-overlay">
                                Выдан
                            </h4>
                        </div>
                        <h6 class="font-gilroy font-bold text-xs leading-[14px] text-black dark:text-dark-main mb-[4px]">
                            Самовывоз со склада 17 июля
                        </h6>
                        <p class="font-gilroy font-bold text-xs leading-[14px] text-description">
                            улица Жеруйык 2, Алматы, Казахстан
                        </p>
                    </div>
                </div>
                <div class="border-t dark:border-t-light-gray-20 flex justify-between items-center pt-[12px]">
                    <router-link to="/order/43523412" class="text-accent flex items-center gap-[8px]">
                        Подробнее
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path class="dark:stroke-dark-main" d="M6 11.3337L9.33333 8.00033L6 4.66699" stroke="#131313" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </router-link>
                    <div class="flex items-center gap-[7px]">
                        <h4 class="text-black dark:text-dark-main font-bold text-base leading-[20px] font-gilroy flex items-center">
                            2 343
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.8876 5.06876L20.9873 11.2326C20.9991 11.2555 21.0028 11.2814 20.9979 11.3065C20.9931 11.3316 20.9798 11.3545 20.9602 11.372L12.5887 18.9663C12.5649 18.9879 12.5332 19 12.5003 19C12.4673 19 12.4357 18.9879 12.4119 18.9663L4.04034 11.3725C4.02071 11.355 4.0075 11.3321 4.00263 11.307C3.99776 11.2819 4.0015 11.256 4.0133 11.2331L7.11299 5.06923C7.123 5.04853 7.13908 5.031 7.15933 5.01872C7.17958 5.00645 7.20314 4.99995 7.22718 5.00001H17.7724C17.7965 4.99972 17.8203 5.00606 17.8407 5.01826C17.8611 5.03046 17.8774 5.048 17.8876 5.06876Z" fill="#50AF95"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.4244 11.8653C11.4845 11.8696 11.795 11.8871 12.4877 11.8871C13.0386 11.8871 13.4298 11.8715 13.567 11.8653C15.6961 11.7767 17.2852 11.4258 17.2852 11.0057C17.2852 10.5856 15.6961 10.2352 13.567 10.1451V11.5159C13.4277 11.5254 13.0291 11.5477 12.4782 11.5477C11.817 11.5477 11.486 11.5216 11.4264 11.5164V10.1461C9.30183 10.2357 7.71617 10.5866 7.71617 11.0057C7.71617 11.4249 9.30133 11.7757 11.4264 11.8649L11.4244 11.8653ZM11.4244 10.0043V8.77772H8.45942V6.90723H16.532V8.77772H13.5675V10.0038C15.977 10.1086 17.7891 10.5605 17.7891 11.102C17.7891 11.6434 15.977 12.0948 13.5675 12.2001V16.1307H11.4249V12.1987C9.02086 12.0939 7.21183 11.6425 7.21183 11.1015C7.21183 10.5605 9.01936 10.1091 11.4249 10.0038L11.4244 10.0043Z" fill="white"/>
                            </svg>
                        </h4>
                        <h5 class="font-gilroy font-medium text-[12px] leading-[14px] text-description">
                            ~ 786 833,35 ₸
                        </h5>
                    </div>
                </div>
            </div>
        </div>
        <!-- Empty order -->
        <!-- <div class="bg-light-overlay rounded-3xl p-[16px] mb-[16px] flex flex-col items-center text-center">
            <svg width="76" height="75" viewBox="0 0 76 75" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M44.2501 37.5L31.7501 50M31.75 37.5L44.2501 50" stroke="#C5C3DC" stroke-opacity="0.8" stroke-width="4.6875" stroke-linecap="round"/>
                <path d="M28.6246 18.75V15.625C28.6246 10.4473 32.8218 6.25 37.9996 6.25C43.1774 6.25 47.3746 10.4473 47.3746 15.625V18.75M12.1927 64.2328C15.9415 68.75 22.9188 68.75 36.873 68.75H39.1268C53.0811 68.75 60.0586 68.75 63.8074 64.2328C67.5561 59.7159 66.2702 52.8581 63.6986 39.1428C61.8699 29.3891 60.9555 24.5123 57.4839 21.6311C54.0124 18.75 49.0505 18.75 39.1268 18.75H36.873C26.9495 18.75 21.9877 18.75 18.5161 21.6311C15.0446 24.5123 14.1302 29.3891 12.3014 39.1428C9.72973 52.8581 8.44391 59.7159 12.1927 64.2328Z" stroke="#C5C3DC" stroke-opacity="0.8" stroke-width="4.6875" stroke-linecap="round"/>
            </svg>
            <h6 class="font-gilroy font-bold text-[20px] leading-[24.18px] mt-[30px]">
                Нет истории заказов
            </h6>
            <p class="mb-[30px] font-gilroy font-medium text-base leading-[20px] text-description">
                История появится после выдачи товара
            </p>
            <router-link to="/" class="pb-[20px]">
                <Btn to="/" class="w-[140px]">
                    На главную
                </Btn>
            </router-link>

        </div> -->
        <!-- Empty order end -->
    </div>
</template>

