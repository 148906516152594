<script>
export default {

}
</script>

<template>
    <header class="relative z-[1]">
        <div class="absolute top-0 left-0 h-[calc(100%_+_76px)] w-full z-[-1] bg-accent"></div>
        <div class="container">
            <div class="flex items-center justify-between pt-2 pb-[10.5px] text-light-overlay treacking-[-0.4px]">
                <div class="w-[95px]">
                    <button class="text-[17px] leading-[22px]">Отмена</button>
                </div>
                <div class="flex flex-col items-center gap-[1px]">
                    <h3 class="text-[17px] font-semibold leading-[22px] not-italic">Global FS</h3>
                    <h4 class="font-roboto text-[13px] leading-5 tracking-[0.1px]">бот</h4>
                </div>
                <div class="w-[95px] flex justify-end">
                    <button>
                        <img src="@/assets/images/more-circle.svg" alt="">
                    </button>
                </div>
            </div>
            <div class="flex items-center justify-between py-[7.5px]">
                <div class="w-[109px]">
                    <p class="font-gilroy font-medium text-xs leading-[14px] text-grey-accent mb-[3px]">Курс Tether USDt</p>
                    <h3 class="text-white-accent font-gilroy font-bold text-xl leading-6">473,99 ₸*</h3>
                </div>
                <img src="@/assets/images/light-logo.svg" alt="">
                <div class="w-[109px] flex flex-col items-end">
                    <div>
                        <p class="font-gilroy font-medium text-xs leading-[14px] text-grey-accent mb-[3px]">Мой балланс</p>
                        <h3 class="text-white-accent font-gilroy font-bold text-xl leading-6 flex items-center gap-2">
                            <span>2 000</span>
                            <img src="@/assets/images/tether.svg" alt="">
                        </h3>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>