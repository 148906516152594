<script>
export default {
    name: "Payment"
}
</script>

<template>
    <div class="py-[20px] mb-[24px]">
        <h6 class="font-gilroy font-bold text-[32px] leading-[38px] text-light-main dark:text-dark-main">
            Пополнить счет
        </h6>
    </div>
    <div class="bg-light-overlay dark:bg-transparent rounded-3xl p-[16px]">
        <div class="h-[273px] flex items-center justify-center mb-[66px] dark:bg-dark-main dark:rounded-3xl">
            <img src="@/assets/images/qr-code.svg" alt="">
        </div>
        <div>
            <CopyBtn code="TZ46dsfsdfs$Fsdfsdfsd4435DS43" />
            <p class="font-gilroy font-medium text-[12px] leading-[14.12px] text-attention">
                Внимание, к оплате принимаются только токены USDT на TRC-20
            </p>
        </div>
    </div>
</template>
