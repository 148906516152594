<script>
export default {
    name: "Order created",
}
</script>

<template>
    <div class="font-gilroy">
        <div class="relative mb-6">
            <h3 class="text-center py-[29px] text-light-main dark:text-dark-main font-bold text-xl leading-6">Заказ создан</h3>
            <router-link to="/order/payment" class="absolute right-0 top-1/2 translate-y-[-50%] w-10 h-10 flex items-center justify-center rounded-xl bg-light-gray-20">
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path class="dark:stroke-dark-main" d="M11 1.00004L1.00004 11M1 1L11 11" stroke="#131313" stroke-width="1.5" stroke-linecap="round"/>
                </svg>
            </router-link>
        </div>
        <div class="p-4 rounded-3xl bg-light-overlay dark:bg-transparent">
            <h3 class="text-light-main dark:text-dark-main text-xl font-bold mb-2">Пополнить счет</h3>
            <p class="text-description text-xs leading-[14px] font-medium mb-1">На вашем счету нехватает средств для оплаты заказа, пополните свой кошелек</p>
            <div class="h-[263px] flex items-center justify-center mb-1 dark:bg-white dark:rounded-3xl">
                <img src="@/assets/images/qr-code.svg" alt="">
            </div>
            <CopyBtn code="TZ46dsfsdfs$Fsdfsdfsd4435DS43"/>
            <p class="text-attention text-xs leading-[14px] font-medium">Внимание, к оплате принимаются только токены USDT <br> на TRC-20</p>
        </div>
        <Btn @click="$router.push({name: 'home'})" class="mt-2 mb-6">На главную</Btn>
    </div>
</template>