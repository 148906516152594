<script>
import { Swiper, SwiperSlide } from 'swiper/vue';

import 'swiper/css';

import 'swiper/css/free-mode';

import { FreeMode } from 'swiper/modules';

export default {
    name: "Filter",
    components: {
        Swiper,
        SwiperSlide,
    },
    setup() {
        return {
            modules: [FreeMode],
        };
    },
    data () {
        return {
            tab: 1,
            brand: [
                {title: 'Apple', selected: false},
                {title: 'Samsung', selected: false},
                {title: 'NothingPhone', selected: false},
            ],
            model: [
                {title: 'Samsung galaxy fold 4', selected: false},
                {title: 'Samsung galaxy flip 4', selected: false},
                {title: 'IPhone 15 Pro max', selected: false},
                {title: 'Samsung galaxy s24', selected: false},
                {title: 'Samsung galaxy s24 ultra', selected: false},
                {title: 'Samsung galaxy s23', selected: false},
            ]
        }
    },
    computed: {
        dataSelect () {
            let t = true;
            this.brand.forEach(d => {
                if (d.selected) t = false;
            });
            this.model.forEach(d => {
                if (d.selected) t = false;
            });
            return t;
        }
    }
}
</script>

<template>
    <div class="pb-[95px]">
        <div class="w-full flex items-center justify-between py-5">
            <div class="w-1/3">
                <button class="text-accent font-gilroy font-medium text-base leading-5">Сбросить</button>
            </div>
            <div class="w-1/3">
                <h3 class="text-center text-light-main dark:text-dark-main font-gilroy text-xl font-bold leading-6">Фильтры</h3>
            </div>
            <div class="w-1/3 flex justify-end">
                <router-link to="/">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path class="dark:stroke-dark-main" d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#131313" stroke-width="1.5"/>
                        <path class="dark:stroke-dark-main" d="M14.5 9.50002L9.50002 14.5M9.5 9.5L14.5 14.5" stroke="#131313" stroke-width="1.5" stroke-linecap="round"/>
                    </svg>
                </router-link>
            </div>
        </div>

        <!-- Tab head -->
        <swiper
            :slidesPerView="'auto'"
            :freeMode="true"
            :modules="modules"
            class="overflow-visible my-6"
        >
            <swiper-slide class="w-auto">
                <div class="flex-shrink-0 flex items-stretch gap-2">
                    <button
                        @click="tab = 1"
                        class="h-9 pl-[14px] pr-3 rounded-full flex items-center gap-[10px] font-gilroy font-medium text-xs"
                        :class="tab == 1 ? 'bg-accent text-white' : 'bg-light-overlay dark:bg-transparent text-light-main dark:text-dark-main'"
                    >
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path class="dark:stroke-dark-main" d="M2.66699 6.66683C2.66699 4.15267 2.66699 2.8956 3.44804 2.11454C4.22909 1.3335 5.48617 1.3335 8.00033 1.3335C10.5145 1.3335 11.7716 1.3335 12.5526 2.11454C13.3337 2.8956 13.3337 4.15267 13.3337 6.66683V9.3335C13.3337 11.8476 13.3337 13.1048 12.5526 13.8858C11.7716 14.6668 10.5145 14.6668 8.00033 14.6668C5.48617 14.6668 4.22909 14.6668 3.44804 13.8858C2.66699 13.1048 2.66699 11.8476 2.66699 9.3335V6.66683Z" :stroke="tab == 1 ? '#ffffff' : '#131313'"/>
                            <path class="dark:stroke-dark-main" d="M10 12.6665H6" :stroke="tab == 1 ? '#FFFFFF' : '#131313'" stroke-linecap="round"/>
                        </svg>
                        <span>Смартфоны</span>
                    </button>
                    <button
                        @click="tab = 2"
                        class="h-9 pl-[14px] pr-3 rounded-full flex items-center gap-[10px] font-gilroy font-medium text-xs"
                        :class="tab == 2 ? 'bg-accent text-white' : 'bg-light-overlay dark:bg-transparent text-light-main dark:text-dark-main'"
                    >
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path class="dark:stroke-dark-main" d="M2 6C2 4.11438 2 3.17157 2.58579 2.58579C3.17157 2 4.11438 2 6 2H10C11.8856 2 12.8284 2 13.4142 2.58579C14 3.17157 14 4.11438 14 6V9.33333C14 10.5904 14 11.2189 13.6095 11.6095C13.2189 12 12.5904 12 11.3333 12H4.66667C3.40959 12 2.78105 12 2.39053 11.6095C2 11.2189 2 10.5904 2 9.33333V6Z" :stroke="tab == 2 ? '#FFFFFF' : '#131313'"/>
                            <path class="dark:stroke-dark-main" d="M14.6663 14H1.33301" :stroke="tab == 2 ? '#FFFFFF' : '#131313'" stroke-linecap="round"/>
                            <path class="dark:stroke-dark-main" d="M10 10H6" :stroke="tab == 2 ? '#FFFFFF' : '#131313'" stroke-linecap="round"/>
                        </svg>
                        <span>Ноутбуки</span>
                    </button>
                    <button
                        @click="tab = 3"
                        class="h-9 pl-[14px] pr-3 rounded-full flex items-center gap-[10px] font-gilroy font-medium text-xs"
                        :class="tab == 3 ? 'bg-accent text-white' : 'bg-light-overlay dark:bg-transparent text-light-main dark:text-dark-main'"
                    >
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path class="dark:fill-dark-main" d="M10.6891 13.6566C10.4411 13.778 10.3386 14.0775 10.4601 14.3255C10.5815 14.5735 10.8811 14.676 11.1291 14.5546L10.6891 13.6566ZM6.77447 14.1551C3.95839 13.5826 1.83301 11.0712 1.83301 8.05563H0.833008C0.833008 11.5504 3.29685 14.4685 6.57524 15.135L6.77447 14.1551ZM1.83301 8.05563C1.83301 4.61533 4.59781 1.8335 7.99967 1.8335V0.833496C4.03773 0.833496 0.833008 4.07087 0.833008 8.05563H1.83301ZM7.99967 1.8335C11.4015 1.8335 14.1663 4.61533 14.1663 8.05563H15.1663C15.1663 4.07087 11.9616 0.833496 7.99967 0.833496V1.8335ZM8.49967 13.4334V10.0723H7.49967V13.4334H8.49967ZM14.1663 8.05563C14.1663 10.52 12.7469 12.6486 10.6891 13.6566L11.1291 14.5546C13.5195 13.3836 15.1663 10.9128 15.1663 8.05563H14.1663ZM6.57524 15.135C7.68061 15.3598 8.49967 14.4122 8.49967 13.4334H7.49967C7.49967 13.9395 7.11281 14.2238 6.77447 14.1551L6.57524 15.135Z" :fill="tab == 3 ? '#FFFFFF' : '#131313'"/>
                            <path class="dark:stroke-dark-main" d="M6 7.86683C6 7.5723 6.23878 7.3335 6.53333 7.3335H9.46667C9.7612 7.3335 10 7.5723 10 7.86683V8.00016C10 9.10476 9.1046 10.0002 8 10.0002C6.8954 10.0002 6 9.10476 6 8.00016V7.86683Z" :stroke="tab == 3 ? '#FFFFFF' : '#131313'"/>
                            <path class="dark:stroke-dark-main" d="M9 7.33333V6" :stroke="tab == 3 ? '#FFFFFF' : '#131313'" stroke-linecap="round"/>
                            <path class="dark:stroke-dark-main" d="M7 7.33333V6" :stroke="tab == 3 ? '#FFFFFF' : '#131313'" stroke-linecap="round"/>
                        </svg>
                        <span>Бытовая техника</span>
                    </button>
                    <button
                        @click="tab = 4"
                        class="h-9 pl-[14px] pr-3 rounded-full flex items-center gap-[10px] font-gilroy font-medium text-xs"
                        :class="tab == 4 ? 'bg-accent text-white' : 'bg-light-overlay dark:bg-transparent text-light-main dark:text-dark-main'"
                    >
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path class="dark:stroke-dark-main" d="M5.66699 7.3335V9.3335M4.66699 8.3335H6.66699" :stroke="tab == 4 ? '#FFFFFF' : '#131313'" stroke-linecap="round"/>
                            <path class="dark:stroke-dark-main" d="M14.6128 11.5842L13.8896 8.57337C13.4807 6.87144 13.2763 6.02043 12.6965 5.46231C12.5497 5.32106 12.3876 5.19586 12.2129 5.08895C11.5224 4.6665 10.6244 4.6665 8.82847 4.6665H7.17087C5.37492 4.6665 4.47693 4.6665 3.7865 5.08895C3.61177 5.19586 3.44958 5.32106 3.30284 5.46231C2.72299 6.02044 2.51858 6.87144 2.10976 8.57337L1.38654 11.5842C1.1323 12.6427 1.80811 13.7012 2.89577 13.9482C3.81298 14.1564 4.75647 13.7208 5.17076 12.8976L5.2561 12.728C5.63648 11.9722 6.42582 11.4931 7.29061 11.4931H8.70874C9.57354 11.4931 10.3629 11.9722 10.7433 12.728L10.8286 12.8976C11.2429 13.7208 12.1863 14.1564 13.1036 13.9482C14.1912 13.7012 14.8671 12.6427 14.6128 11.5842Z" :stroke="tab == 4 ? '#FFFFFF' : '#131313'"/>
                            <path class="dark:stroke-dark-main" d="M8 4.66667V4C8 3.63181 8.29847 3.33333 8.66667 3.33333H9.33333C9.70153 3.33333 10 3.03485 10 2.66667V2" :stroke="tab == 4 ? '#FFFFFF' : '#131313'" stroke-linecap="round"/>
                            <path class="dark:fill-dark-main" d="M10.6663 7.33317C10.6663 7.70137 10.3679 7.99984 9.99967 7.99984C9.63147 7.99984 9.33301 7.70137 9.33301 7.33317C9.33301 6.96497 9.63147 6.6665 9.99967 6.6665C10.3679 6.6665 10.6663 6.96497 10.6663 7.33317Z" :fill="tab == 4 ? '#FFFFFF' : '#131313'"/>
                            <path class="dark:fill-dark-main" d="M12.0003 9.33317C12.0003 9.70137 11.7019 9.99984 11.3337 9.99984C10.9655 9.99984 10.667 9.70137 10.667 9.33317C10.667 8.96497 10.9655 8.6665 11.3337 8.6665C11.7019 8.6665 12.0003 8.96497 12.0003 9.33317Z" :fill="tab == 4 ? '#FFFFFF' : '#131313'"/>
                        </svg>
                        <span>Приставки</span>
                    </button>
                </div>
            </swiper-slide>
        </swiper>
        <!-- Tab head end -->

        <!-- Tab body -->
        <!-- Смартфоны -->
        <div v-if="tab == 1" class="flex flex-col gap-6 w-[calc(100%_+_32px)] -ml-4">
            <div class="px-4 pt-4 pb-6 bg-light-overlay dark:bg-transparent rounded-3xl">
                <h3 class="text-light-main dark:text-dark-main font-gilroy font-bold text-xl leading-6 mb-5">Бренд</h3>
                <ul>
                    <li
                        @click="data.selected = !data.selected"
                        v-for="(data, idx) in brand"
                        :key="idx"
                        class="flex items-center justify-between"
                        :class="{
                            'border-b dark:border-b-light-gray-20 pb-4' : idx + 1 != brand.length,
                            'pt-4': idx != 0,
                        }"
                    >
                        <p class="text-light-main dark:text-dark-main font-gilroy font-medium text-base leading-5">{{data.title}}</p>
                        <button
                            class="w-6 h-6 rounded-lg flex items-center justify-center"
                            :class="data.selected ? 'bg-accent' : 'bg-light-gray-20'"
                        >
                            <svg v-if="data.selected" width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.5 4.5L3.5 6.5L8.5 1.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </button>
                    </li>
                </ul>
            </div>
            <div class="px-4 pt-4 pb-6 bg-light-overlay dark:bg-transparent rounded-3xl">
                <h3 class="text-light-main dark:text-dark-main font-gilroy font-bold text-xl leading-6 mb-5">Модель</h3>
                <ul>
                    <li
                        @click="data.selected = !data.selected"
                        v-for="(data, idx) in model"
                        :key="idx"
                        class="flex items-center justify-between"
                        :class="{
                            'border-b dark:border-b-light-gray-20 pb-4' : idx + 1 != model.length,
                            'pt-4': idx != 0,
                        }"
                    >
                        <p class="text-light-main dark:text-dark-main font-gilroy font-medium text-base leading-5">{{data.title}}</p>
                        <button
                            class="w-6 h-6 rounded-lg flex items-center justify-center"
                            :class="data.selected ? 'bg-accent' : 'bg-light-gray-20'"
                        >
                            <svg v-if="data.selected" width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.5 4.5L3.5 6.5L8.5 1.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </button>
                    </li>
                </ul>
            </div>
        </div>
        <!-- Смартфоны end -->

        <!-- Ноутбуки -->
        <div v-if="tab == 2" class="flex flex-col gap-6 w-[calc(100%_+_32px)] -ml-4">
            <div class="px-4 pt-4 pb-6 bg-light-overlay dark:bg-transparent rounded-3xl">
                <h3 class="text-light-main dark:text-dark-main font-gilroy font-bold text-xl leading-6 mb-5">Бренд</h3>
                <ul>
                    <li
                        @click="data.selected = !data.selected"
                        v-for="(data, idx) in brand"
                        :key="idx"
                        class="flex items-center justify-between"
                        :class="{
                            'border-b dark:border-b-light-gray-20 pb-4' : idx + 1 != brand.length,
                            'pt-4': idx != 0,
                        }"
                    >
                        <p class="text-light-main dark:text-dark-main font-gilroy font-medium text-base leading-5">{{data.title}}</p>
                        <button
                            class="w-6 h-6 rounded-lg flex items-center justify-center"
                            :class="data.selected ? 'bg-accent' : 'bg-light-gray-20'"
                        >
                            <svg v-if="data.selected" width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.5 4.5L3.5 6.5L8.5 1.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </button>
                    </li>
                </ul>
            </div>
            <div class="px-4 pt-4 pb-6 bg-light-overlay dark:bg-transparent rounded-3xl">
                <h3 class="text-light-main dark:text-dark-main font-gilroy font-bold text-xl leading-6 mb-5">Модель</h3>
                <ul>
                    <li
                        @click="data.selected = !data.selected"
                        v-for="(data, idx) in model"
                        :key="idx"
                        class="flex items-center justify-between"
                        :class="{
                            'border-b dark:border-b-light-gray-20 pb-4' : idx + 1 != model.length,
                            'pt-4': idx != 0,
                        }"
                    >
                        <p class="text-light-main dark:text-dark-main font-gilroy font-medium text-base leading-5">{{data.title}}</p>
                        <button
                            class="w-6 h-6 rounded-lg flex items-center justify-center"
                            :class="data.selected ? 'bg-accent' : 'bg-light-gray-20'"
                        >
                            <svg v-if="data.selected" width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.5 4.5L3.5 6.5L8.5 1.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </button>
                    </li>
                </ul>
            </div>
        </div>
        <!-- Ноутбуки end -->

        <!-- Бытовая техника -->
        <div v-if="tab == 3" class="flex flex-col gap-6 w-[calc(100%_+_32px)] -ml-4">
            <div class="px-4 pt-4 pb-6 bg-light-overlay dark:bg-transparent rounded-3xl">
                <h3 class="text-light-main dark:text-dark-main font-gilroy font-bold text-xl leading-6 mb-5">Бренд</h3>
                <ul>
                    <li
                        @click="data.selected = !data.selected"
                        v-for="(data, idx) in brand"
                        :key="idx"
                        class="flex items-center justify-between"
                        :class="{
                            'border-b dark:border-b-light-gray-20 pb-4' : idx + 1 != brand.length,
                            'pt-4': idx != 0,
                        }"
                    >
                        <p class="text-light-main dark:text-dark-main font-gilroy font-medium text-base leading-5">{{data.title}}</p>
                        <button
                            class="w-6 h-6 rounded-lg flex items-center justify-center"
                            :class="data.selected ? 'bg-accent' : 'bg-light-gray-20'"
                        >
                            <svg v-if="data.selected" width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.5 4.5L3.5 6.5L8.5 1.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </button>
                    </li>
                </ul>
            </div>
            <div class="px-4 pt-4 pb-6 bg-light-overlay dark:bg-transparent rounded-3xl">
                <h3 class="text-light-main dark:text-dark-main font-gilroy font-bold text-xl leading-6 mb-5">Модель</h3>
                <ul>
                    <li
                        @click="data.selected = !data.selected"
                        v-for="(data, idx) in model"
                        :key="idx"
                        class="flex items-center justify-between"
                        :class="{
                            'border-b dark:border-b-light-gray-20 pb-4' : idx + 1 != model.length,
                            'pt-4': idx != 0,
                        }"
                    >
                        <p class="text-light-main dark:text-dark-main font-gilroy font-medium text-base leading-5">{{data.title}}</p>
                        <button
                            class="w-6 h-6 rounded-lg flex items-center justify-center"
                            :class="data.selected ? 'bg-accent' : 'bg-light-gray-20'"
                        >
                            <svg v-if="data.selected" width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.5 4.5L3.5 6.5L8.5 1.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </button>
                    </li>
                </ul>
            </div>
        </div>
        <!-- Бытовая техника end -->

        <!-- Приставки -->
        <div v-if="tab == 4" class="flex flex-col gap-6 w-[calc(100%_+_32px)] -ml-4">
            <div class="px-4 pt-4 pb-6 bg-light-overlay dark:bg-transparent rounded-3xl">
                <h3 class="text-light-main dark:text-dark-main font-gilroy font-bold text-xl leading-6 mb-5">Бренд</h3>
                <ul>
                    <li
                        @click="data.selected = !data.selected"
                        v-for="(data, idx) in brand"
                        :key="idx"
                        class="flex items-center justify-between"
                        :class="{
                            'border-b dark:border-b-light-gray-20 pb-4' : idx + 1 != brand.length,
                            'pt-4': idx != 0,
                        }"
                    >
                        <p class="text-light-main dark:text-dark-main font-gilroy font-medium text-base leading-5">{{data.title}}</p>
                        <button
                            class="w-6 h-6 rounded-lg flex items-center justify-center"
                            :class="data.selected ? 'bg-accent' : 'bg-light-gray-20'"
                        >
                            <svg v-if="data.selected" width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.5 4.5L3.5 6.5L8.5 1.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </button>
                    </li>
                </ul>
            </div>
            <div class="px-4 pt-4 pb-6 bg-light-overlay dark:bg-transparent rounded-3xl">
                <h3 class="text-light-main dark:text-dark-main font-gilroy font-bold text-xl leading-6 mb-5">Модель</h3>
                <ul>
                    <li
                        @click="data.selected = !data.selected"
                        v-for="(data, idx) in model"
                        :key="idx"
                        class="flex items-center justify-between"
                        :class="{
                            'border-b dark:border-b-light-gray-20 pb-4' : idx + 1 != model.length,
                            'pt-4': idx != 0,
                        }"
                    >
                        <p class="text-light-main dark:text-dark-main font-gilroy font-medium text-base leading-5">{{data.title}}</p>
                        <button
                            class="w-6 h-6 rounded-lg flex items-center justify-center"
                            :class="data.selected ? 'bg-accent' : 'bg-light-gray-20'"
                        >
                            <svg v-if="data.selected" width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.5 4.5L3.5 6.5L8.5 1.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </button>
                    </li>
                </ul>
            </div>
        </div>
        <!-- Приставки end -->
        <!-- Tab body end -->

        <div class="fixed z-[5] bottom-0 left-0 w-full py-[27px] bg-light-overlay dark:bg-dark-overlay border-t-[1.5px] dark:border-t-0 border-t-light-bg border-solid">
            <div class="container">
                <Btn :disabled="dataSelect">Применить</Btn>
            </div>
        </div>
    </div>
</template>