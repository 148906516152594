<script>
export default {
    name: 'OrderId'
}
</script>

<template>
  <div class="w-[calc(100%_+_32px)] -ml-4">
      <div class="flex items-center gap-[40px] py-[20px] px-[16px] w-full relative">
        <router-link to="/profile" class="rounded-xl bg-[#C5C3DC33] w-[40px] absolute left-[16px] h-[40px] flex items-center justify-center">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path class="dark:stroke-dark-main" d="M14 7L9 12L14 17" stroke="#131313" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </router-link>
          <h3 class="font-gilroy dark:text-dark-main font-bold text-[20px] leading-[24px] text-center w-full">
            О нас
          </h3>
      </div>
      <div class="bg-light-overlay dark:bg-transparent rounded-[24px_24px_0_0] px-[16px] py-[20px] flex flex-col gap-[8px]">
        <p class="font-gilroy font-medium text-base leading-[26px] text-light-main dark:text-dark-main">
          May I have your attention please? May I have your attention please? Will the real Slim Shady please stand up? I repeat, will the real Slim Shady please stand up? We're gonna have a problem here..
        </p>
        <p class="font-gilroy font-medium text-base leading-[26px] text-light-main dark:text-dark-main">
          Y'all act like you never seen a white person before Jaws all on the floor like Pam, like Tommy just burst in the door and started whoopin her ass worse than before they first were divorce, throwin her over furniture (Ahh!)
        </p>
        <p class="font-gilroy font-medium text-base leading-[26px] text-light-main dark:text-dark-main">
          It's the return of the… “Ah, wait, no way, you're kidding, he didn't just say what I think he did, did he?” And Dr. Dre said… nothing you idiots! Dr. Dre's dead, he's locked in my basement! (Ha-ha!)
        </p>
        <p class="font-gilroy font-medium text-base leading-[26px] text-light-main dark:text-dark-main">
          Feminist women love Eminem [*vocal turntable: chigga chigga chigga*] “Slim Shady, I'm sick of him Look at him, walkin around grabbin his you-know-what Flippin the you-know-who,” “Yeah, but he's so cute though!” Yeah, I probably got a couple of screws up in my head loose But no worse, than what's goin on in your parents' bedrooms Sometimes, I wanna get on TV and just let loose, but can't but it's cool for Tom Green to hump a dead moose “My bum is on your lips, my bum is on your lips” And if I'm lucky, you might just give it a little kiss And that's the message that we deliver to little kids And expect them not to know what a woman's clitoris is Of course they gonna know what intercourse is By the time they hit fourth grade They got the Discovery Channel don't they? “We ain't nothing but mammals..” Well, some of us cannibals who cut other people open like cantaloupes [*SLURP*] But if we can hump dead animals and antelopes then there's no reason that a man and another man can't elope [*EWWW!*] But if you feel like I feel, I got the antidote Women wave your pantyhose, sing the chorus and it goes
          [Chorus: Eminem (repeat 2X)]
          I'm Slim Shady, yes I'm the real Shady All you other Slim Shadys are just imitating So won't the real Slim Shady please stand up,please stand up, please stand up?
          [Eminem] Will Smith don't gotta cuss in his raps to sell his records; well I do, so fuck him and fuck you too! You think I give a damn about a Grammy? Half of you critics can't even stomach me, let alone stand me “But Slim, what if you win, wouldn't it be weird?” Why? So you guys could just lie to get me here? So you can, sit me here next to Britney Spears? Shit, Christina Aguilera better switch me chairs so I can sit next to Carson Daly and Fred Durst and hear 'em argue over who she gave head to first You little bitch, put me on blast on MTV “Yeah, he's cute, but I think he's married to Kim, hee-hee!” I should download her audio on MP3 and show the whole world how you gave Eminem VD [*AHHH!*] I'm sick of you little girl and boy groups, all you do is annoy me so I have been sent here to destroy you [*bzzzt*]And there's a million of us just like me who cuss like me; who just don't give a fuck like me who dress like me; walk, talk and act like me and just might be the next best thing but not quite me!
          [Chorus]
          [Eminem] I'm like a head trip to listen to, cause I'm only givin you things you joke about with your friends inside your living room The only difference is I got the balls to say it in front of y'all and I don't gotta be false or sugarcoated at all I just get on the mic and spit it and whether you like to admit it [*ERR*] I just shit it better than ninety percent of you rappers out can Then you wonder how can kids eat up these albums like valiums It's funny; cause at the rate I'm goin when I'm thirty I'll be the only person in the nursin home flirting Pinchin nurses asses when I'm jackin off with Jergens And I'm jerkin but this whole bag of Viagra isn't working And every single person is a Slim Shady lurkin He could be workin at Burger King, spittin on your onion rings [*HACH*] Or in the parkin lot, circling Screaming “I don't give a fuck!” with his windows down and his system up So, will the real Shady please stand up? And put one of those fingers on each hand up? And be proud to be outta your mind and outta control and one more time, loud as you can, how does it go?
          [Chorus 2X]
          [Eminem] Ha ha Guess there's a Slim Shady in all of us Fuck it, let's all stand up
        </p>
      </div>
  </div>
</template>
