<script>
import { Swiper, SwiperSlide } from 'swiper/vue';

import 'swiper/css';
import 'swiper/css/free-mode';

import { FreeMode } from 'swiper/modules';

export default {
  name: 'Home',
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [FreeMode],
    };
  },
  data () {
    return {
      products: [
        {count: 1},
        {count: 1},
        {count: 1},
        {count: 1},
        {count: 1},
        {count: 1},
      ],
      calculation: null,
    }
  }
}
</script>

<template>
  <div class="pt-5">
    <swiper
      :slidesPerView="'auto'"
      :freeMode="true"
      :modules="modules"
      class="mb-5 overflow-visible"
    >
      <swiper-slide class="w-auto">
        <div class="flex gap-2 items-center">
          <button class="bg-light-overlay dark:bg-transparent rounded-full px-4 h-9 flex items-center justify-center">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path class="dark:fill-dark-main" d="M12 13.125C12.3013 13.125 12.5733 13.3052 12.6907 13.5827C12.8081 13.8601 12.7482 14.1808 12.5384 14.3971L8.53844 18.5221C8.39719 18.6678 8.20293 18.75 8.00002 18.75C7.79711 18.75 7.60285 18.6678 7.46159 18.5221L3.46159 14.3971C3.25188 14.1808 3.19192 13.8601 3.30934 13.5827C3.42676 13.3052 3.69877 13.125 4.00002 13.125H7.25002V6C7.25002 5.58579 7.5858 5.25 8.00002 5.25C8.41423 5.25 8.75002 5.58579 8.75002 6V13.125H12Z" fill="#131313"/>
              <path class="dark:fill-dark-main" d="M20 10.875C20.3013 10.875 20.5733 10.6948 20.6907 10.4173C20.8081 10.1399 20.7482 9.81916 20.5384 9.60289L16.5384 5.47789C16.3972 5.33222 16.2029 5.25 16 5.25C15.7971 5.25 15.6029 5.33222 15.4616 5.47789L11.4616 9.60289C11.2519 9.81916 11.1919 10.1399 11.3093 10.4173C11.4268 10.6948 11.6988 10.875 12 10.875H15.25V18C15.25 18.4142 15.5858 18.75 16 18.75C16.4142 18.75 16.75 18.4142 16.75 18V10.875H20Z" fill="#131313"/>
            </svg>
          </button>
          <router-link to="/filter" class="bg-light-overlay dark:bg-transparent rounded-full px-4 h-9 flex items-center justify-center">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path class="dark:fill-dark-main" d="M19 3H5C3.58579 3 2.87868 3 2.43934 3.4122C2 3.8244 2 4.48782 2 5.81466V6.50448C2 7.54232 2 8.06124 2.2596 8.49142C2.5192 8.9216 2.99347 9.18858 3.94202 9.72255L6.85504 11.3624C7.49146 11.7206 7.80967 11.8998 8.03751 12.0976C8.51199 12.5095 8.80408 12.9935 8.93644 13.5872C9 13.8722 9 14.2058 9 14.8729V17.5424C9 18.452 9 18.9067 9.25192 19.2613C9.50385 19.6158 9.95128 19.7907 10.8462 20.1406C12.7248 20.875 13.6641 21.2422 14.3321 20.8244C15 20.4066 15 19.4519 15 17.5424V14.8729C15 14.2058 15 13.8722 15.0636 13.5872C15.1959 12.9935 15.488 12.5095 15.9625 12.0976C16.1903 11.8998 16.5085 11.7206 17.145 11.3624L20.058 9.72255C21.0065 9.18858 21.4808 8.9216 21.7404 8.49142C22 8.06124 22 7.54232 22 6.50448V5.81466C22 4.48782 22 3.8244 21.5607 3.4122C21.1213 3 20.4142 3 19 3Z" fill="#131313"/>
            </svg>
          </router-link>
          <button class="bg-light-overlay dark:bg-transparent dark:text-dark-main rounded-full px-4 h-9 flex items-center justify-center flex-shrink-0">iPhone 15 PRO MAX</button>
          <button class="bg-light-overlay dark:bg-transparent dark:text-dark-main rounded-full px-4 h-9 flex items-center justify-center flex-shrink-0">iPhone 15</button>
          <button class="bg-light-overlay dark:bg-transparent dark:text-dark-main rounded-full px-4 h-9 flex items-center justify-center flex-shrink-0">iPhone 14 PRO MAX</button>
          <button class="bg-light-overlay dark:bg-transparent dark:text-dark-main rounded-full px-4 h-9 flex items-center justify-center flex-shrink-0">iPhone 14</button>
          <button class="bg-light-overlay dark:bg-transparent dark:text-dark-main rounded-full px-4 h-9 flex items-center justify-center flex-shrink-0">iPhone 13 PRO</button>
        </div>
      </swiper-slide>
    </swiper>
    <ul class="flex items-start flex-wrap gap-5">
      <li v-for="(product, i) in products" :key="i" class="w-[calc(50%_-_10px)] bg-light-overlay dark:bg-transparent rounded-3xl pb-4">
        <div class="mb-2 h-[169px] flex items-center justify-center dark:bg-white dark:rounded-[20px]">
          <img src="@/assets/images/phone.png" alt="" height="147" class="object-contain">
        </div>
        <div class="px-5">
          <h4 class="flex items-center gap-2 font-gilroy font-bold text-base leading-5 mb-[2px text-light-main dark:text-dark-main">
            <span>1 374</span>
            <img src="@/assets/images/tether.svg" alt="">
          </h4>
          <p class="text-description font-gilroy font-medium text-sm mb-4">~ 259 238,45 ₸</p>
          <!-- <div class="flex items-center gap-1 mb-3 py-[3px] pl-[14.74px] bg-light-bg dark:bg-transparent rounded-full w-full">
            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path class="dark:stroke-dark-main" d="M12.7412 8V12L15.2412 14.5" stroke="#131313" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path class="dark:fill-dark-main" d="M5.07894 6.87053L4.32895 6.8743C4.33102 7.28557 4.6639 7.61845 5.07517 7.62052L5.07894 6.87053ZM7.62072 7.63331C8.03493 7.6354 8.3724 7.3013 8.37448 6.88709C8.37656 6.47288 8.04247 6.13541 7.62826 6.13333L7.62072 7.63331ZM5.81614 4.32121C5.81406 3.907 5.47659 3.57291 5.06238 3.57499C4.64817 3.57707 4.31408 3.91454 4.31616 4.32875L5.81614 4.32121ZM4.56779 10.7849C4.62404 10.3745 4.33696 9.99628 3.92658 9.94003C3.51621 9.88378 3.13793 10.1708 3.08168 10.5812L4.56779 10.7849ZM19.6034 5.13778C15.7832 1.31759 9.60991 1.2789 5.81499 5.07382L6.87565 6.13448C10.0748 2.93537 15.2983 2.95396 18.5428 6.19844L19.6034 5.13778ZM5.87895 18.8622C9.69914 22.6824 15.8725 22.7211 19.6674 18.9262L18.6067 17.8655C15.4076 21.0646 10.1841 21.0461 6.93961 17.8016L5.87895 18.8622ZM19.6674 18.9262C23.4623 15.1313 23.4236 8.95797 19.6034 5.13778L18.5428 6.19844C21.7873 9.44292 21.8058 14.6664 18.6067 17.8655L19.6674 18.9262ZM5.81499 5.07382L4.54861 6.3402L5.60927 7.40086L6.87565 6.13448L5.81499 5.07382ZM5.07517 7.62052L7.62072 7.63331L7.62826 6.13333L5.08271 6.12054L5.07517 7.62052ZM5.82893 6.86676L5.81614 4.32121L4.31616 4.32875L4.32895 6.8743L5.82893 6.86676ZM3.08168 10.5812C2.68025 13.5099 3.6151 16.5984 5.87895 18.8622L6.93961 17.8016C5.01903 15.881 4.22781 13.2652 4.56779 10.7849L3.08168 10.5812Z" fill="#131313"/>
            </svg>
            <p class="font-pt-mono text-base font-bold dark:text-dark-main">13:25:60</p>
          </div> -->
          <h3 class="font-gilroy text-xl font-bold mb-2 text-light-main dark:text-dark-main">iPhone 15 Pro Max</h3>
          <p class="text-description font-gilroy text-xs mb-3">128 GB, Black Titanium, Япония</p>
          <div class="mb-4"> 
            <p class="text-light-main dark:text-dark-main mb-1 font-gilroy text-xs font-medium">Осталось 5 шт</p>
            <div class="w-full h-[3px] rounded-full overflow-hidden bg-light-gray-20 relative">
              <span class="absolute top-0 left-0 h-full rounded-full bg-attention" style="width: 20%;"></span>
            </div>
          </div>
          <div v-if="calculation == i" class="flex items-center justify-between bg-light-gray-20 rounded-xl p-[5px]">
            <button @click="product.count != 1 ? product.count-- : product.count = product.count" class="bg-accent w-[30px] h-[30px] flex items-center justify-center flex-shrink-0 rounded-[7px]">
              <svg width="12" height="2" viewBox="0 0 12 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11 1H1" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
              </svg>
            </button>
            <p class="text-light-main dark:text-dark-main font-gilroy font-bold text-base leading-5">{{product.count}}</p>
            <button @click="product.count++" class="bg-accent w-[30px] h-[30px] flex items-center justify-center flex-shrink-0 rounded-[7px]">
              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11 6H6M6 6H1M6 6V1M6 6V11" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
              </svg>
            </button>
          </div>
          <button v-else @click="calculation = i" class="bg-accent w-full flex items-center justify-center gap-2 py-4 rounded-xl">
            <span class="font-gilroy font-bold text-base leading-4 text-white-accent">17 июля</span>
            <img src="@/assets/images/basket-light.svg" alt="">
          </button>
        </div>
      </li>
    </ul>
  </div>
</template>