<script>
export default {
    name: "Nav"
}
</script>

<template>
    <div class="fixed bottom-0 left-0 z-[2] bg-light-overlay dark:bg-dark-overlay w-full">
        <div class="container py-1 flex items-center justify-between gap-3">
            <router-link to="/" class="h-12 w-full flex items-center justify-center">
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path :class="$route.name == 'home' ? 'fill-[#131313] dark:fill-dark-main' : 'fill-[#C5C3DC] dark:fill-dark-gray-80'" fill-rule="evenodd" clip-rule="evenodd" d="M3.1193 7.82274C2.6001 8.77128 2.6001 9.91549 2.6001 12.2039V13.725C2.6001 17.6258 2.6001 19.5763 3.77167 20.7881C4.94325 22 6.82886 22 10.6001 22H14.6001C18.3713 22 20.257 22 21.4285 20.7881C22.6001 19.5763 22.6001 17.6258 22.6001 13.725V12.2039C22.6001 9.91549 22.6001 8.77128 22.0809 7.82274C21.5617 6.87421 20.6132 6.28551 18.7161 5.10812L16.7161 3.86687C14.7107 2.62229 13.708 2 12.6001 2C11.4922 2 10.4895 2.62229 8.48413 3.86687L6.48413 5.10813C4.58705 6.28551 3.6385 6.87421 3.1193 7.82274ZM9.6001 17.25C9.18589 17.25 8.8501 17.5858 8.8501 18C8.8501 18.4142 9.18589 18.75 9.6001 18.75H15.6001C16.0143 18.75 16.3501 18.4142 16.3501 18C16.3501 17.5858 16.0143 17.25 15.6001 17.25H9.6001Z" fill="#C5C3DC" fill-opacity="0.8"/>
                </svg>
            </router-link>
            <router-link to="/support" class="h-12 w-full flex items-center justify-center">
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path :class="$route.name == 'support' || $route.name == 'support-request' ? 'fill-[#131313] dark:fill-dark-main' : 'fill-[#C5C3DC] dark:fill-dark-gray-80'" fill-rule="evenodd" clip-rule="evenodd" d="M22.8003 12C22.8003 17.5228 18.3231 22 12.8003 22C11.2006 22 9.68865 21.6244 8.34782 20.9565C7.9915 20.7791 7.58422 20.72 7.19968 20.8229L4.97364 21.4185C4.0073 21.677 3.12324 20.793 3.3818 19.8267L3.97741 17.6006C4.0803 17.2161 4.02123 16.8088 3.84375 16.4525C3.17591 15.1116 2.80029 13.5997 2.80029 12C2.80029 6.47715 7.27744 2 12.8003 2C18.3231 2 22.8003 6.47715 22.8003 12ZM16.8003 13C16.248 13 15.8003 12.5523 15.8003 12C15.8003 11.4477 16.248 11 16.8003 11C17.3526 11 17.8003 11.4477 17.8003 12C17.8003 12.5523 17.3526 13 16.8003 13ZM12.8003 13C12.248 13 11.8003 12.5523 11.8003 12C11.8003 11.4477 12.248 11 12.8003 11C13.3526 11 13.8003 11.4477 13.8003 12C13.8003 12.5523 13.3526 13 12.8003 13ZM8.80029 13C8.24801 13 7.80029 12.5523 7.80029 12C7.80029 11.4477 8.24801 11 8.80029 11C9.35257 11 9.80029 11.4477 9.80029 12C9.80029 12.5523 9.35257 13 8.80029 13Z" fill="#C5C3DC" fill-opacity="0.8"/>
                </svg>
            </router-link>
            <router-link to="/basket" class="h-12 w-full flex items-center justify-center">
                <span class="relative">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path :class="$route.name == 'basket' ? 'fill-[#131313] dark:fill-dark-main' : 'fill-[#C5C3DC] dark:fill-dark-gray-80'" d="M2.08416 2.75144C2.22155 2.36068 2.6497 2.15527 3.04047 2.29266L3.34187 2.39862C3.95839 2.61535 4.48203 2.79943 4.89411 3.00163C5.33474 3.21783 5.71259 3.48417 5.99677 3.90003C6.27875 4.31267 6.39517 4.76539 6.4489 5.26177C6.47295 5.48397 6.48564 5.72991 6.49233 6.00024H17.1305C18.8155 6.00024 20.3323 6.00024 20.7762 6.57732C21.2202 7.15441 21.0466 8.02393 20.6995 9.76299L20.1997 12.1877C19.8846 13.7166 19.727 14.481 19.1753 14.9306C18.6236 15.3802 17.8431 15.3802 16.2821 15.3802H10.9792C8.19028 15.3802 6.79583 15.3802 5.92943 14.4664C5.06302 13.5525 4.99979 12.5818 4.99979 9.64024V7.03856C4.99979 6.29861 4.99877 5.8034 4.95761 5.42319C4.91828 5.05984 4.84858 4.87842 4.75832 4.74633C4.67026 4.61747 4.53659 4.49704 4.23336 4.34826C3.91052 4.18985 3.47177 4.0343 2.80416 3.79958L2.54295 3.70774C2.15218 3.57036 1.94678 3.14221 2.08416 2.75144Z" fill="#C5C3DC" fill-opacity="0.8"/>
                        <path :class="$route.name == 'basket' ? 'fill-[#131313] dark:fill-dark-main' : 'fill-[#C5C3DC] dark:fill-dark-gray-80'" d="M7.5 18C8.32843 18 9 18.6716 9 19.5C9 20.3284 8.32843 21 7.5 21C6.67157 21 6 20.3284 6 19.5C6 18.6716 6.67157 18 7.5 18Z" fill="#C5C3DC" fill-opacity="0.8"/>
                        <path :class="$route.name == 'basket' ? 'fill-[#131313] dark:fill-dark-main' : 'fill-[#C5C3DC] dark:fill-dark-gray-80'" d="M16.5 18C17.3284 18 18 18.6715 18 19.5C18 20.3284 17.3284 21 16.5 21C15.6716 21 15 20.3284 15 19.5C15 18.6715 15.6716 18 16.5 18Z" fill="#C5C3DC" fill-opacity="0.8"/>
                    </svg>
                    <span class="absolute w-[14px] h-[14px] top-[-5px] right-[-5.2px] flex items-center justify-center bg-attention text-light-overlay rounded-full font-gilroy font-medium text-[8px] leading-[14px] pt-[2px]">1</span>
                </span>
            </router-link>
            <router-link to="/payment" class="h-12 w-full flex items-center justify-center">
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path :class="$route.name == 'payment' ? 'fill-[#131313] dark:fill-dark-main' : 'fill-[#C5C3DC] dark:fill-dark-gray-80'" fill-rule="evenodd" clip-rule="evenodd" d="M21.301 8.00353C21.2443 7.99996 21.1826 7.99998 21.1187 8L21.1027 8.00001H18.5942C16.5265 8.00001 14.7573 9.62757 14.7573 11.75C14.7573 13.8724 16.5265 15.5 18.5942 15.5H21.1027H21.1187C21.1826 15.5 21.2443 15.5001 21.301 15.4965C22.1409 15.4434 22.8836 14.7862 22.9461 13.8682C22.9502 13.808 22.9501 13.7431 22.9501 13.683V13.6667V9.83334V9.81702C22.9501 9.75688 22.9502 9.69199 22.9461 9.6318C22.8836 8.71381 22.1409 8.05657 21.301 8.00353ZM18.3718 12.75C18.9041 12.75 19.3356 12.3023 19.3356 11.75C19.3356 11.1977 18.9041 10.75 18.3718 10.75C17.8395 10.75 17.4079 11.1977 17.4079 11.75C17.4079 12.3023 17.8395 12.75 18.3718 12.75Z" fill="#C5C3DC" fill-opacity="0.8"/>
                    <path :class="$route.name == 'payment' ? 'fill-[#131313] dark:fill-dark-main' : 'fill-[#C5C3DC] dark:fill-dark-gray-80'" fill-rule="evenodd" clip-rule="evenodd" d="M21.1181 17C21.2672 16.9961 21.3801 17.1342 21.3396 17.2778C21.1389 17.9902 20.8202 18.5975 20.309 19.1088C19.5606 19.8571 18.6116 20.1892 17.4392 20.3469C16.3 20.5 14.8444 20.5 13.0066 20.5H10.8938C9.05603 20.5 7.60039 20.5 6.46118 20.3469C5.28876 20.1892 4.33981 19.8571 3.59144 19.1088C2.84308 18.3604 2.51096 17.4114 2.35334 16.239C2.20017 15.0998 2.20018 13.6442 2.2002 11.8064V11.6936C2.20018 9.85583 2.20017 8.40019 2.35334 7.26098C2.51096 6.08856 2.84308 5.13961 3.59144 4.39124C4.33981 3.64288 5.28876 3.31076 6.46118 3.15314C7.60039 2.99997 9.05602 2.99998 10.8938 3H13.0066C14.8444 2.99998 16.3 2.99997 17.4392 3.15314C18.6116 3.31076 19.5606 3.64288 20.309 4.39124C20.8202 4.90252 21.1388 5.50974 21.3396 6.22218C21.3801 6.36575 21.2672 6.50387 21.1181 6.5L18.5942 6.50001C15.7576 6.50001 13.2573 8.74091 13.2573 11.75C13.2573 14.7591 15.7576 17 18.5942 17H21.1181ZM5.9502 7C5.53599 7 5.2002 7.33579 5.2002 7.75C5.2002 8.16421 5.53599 8.5 5.9502 8.5H9.9502C10.3644 8.5 10.7002 8.16421 10.7002 7.75C10.7002 7.33579 10.3644 7 9.9502 7H5.9502Z" fill="#C5C3DC" fill-opacity="0.8"/>
                </svg>
            </router-link>
            <router-link to="/profile" class="h-12 w-full flex items-center justify-center">
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path :class="$route.name == 'profile' || $route.name == 'order' ? 'fill-[#131313] dark:fill-dark-main' : 'fill-[#C5C3DC] dark:fill-dark-gray-80'" fill-rule="evenodd" clip-rule="evenodd" d="M22.3999 12C22.3999 17.5228 17.9227 22 12.3999 22C6.87705 22 2.3999 17.5228 2.3999 12C2.3999 6.47715 6.87705 2 12.3999 2C17.9227 2 22.3999 6.47715 22.3999 12ZM15.3999 10C15.3999 11.6569 14.0568 13 12.3999 13C10.743 13 9.3999 11.6569 9.3999 10C9.3999 8.34315 10.743 7 12.3999 7C14.0568 7 15.3999 8.34315 15.3999 10ZM12.3999 20.5C14.1839 20.5 15.8396 19.9504 17.2068 19.0112C17.8107 18.5964 18.0687 17.8062 17.7177 17.1632C16.9899 15.8303 15.4901 15 12.3998 15C9.30959 15 7.80987 15.8302 7.08204 17.1632C6.73095 17.8062 6.989 18.5963 7.59286 19.0111C8.96008 19.9503 10.6158 20.5 12.3999 20.5Z" fill="#C5C3DC" fill-opacity="0.8"/>
                </svg>
            </router-link>
        </div>
    </div>
</template>
