<script>
export default {
    name: "Support",
    data () {
        return {
            reason: 'Оплата',
            openReason: false,
            msg: '',
            modal: false,
        }
    },
    watch: {
        modal () {
            if (this.modal) {
                document.querySelector('body').style.overflow = 'hidden';
            } else {
                document.querySelector('body').style.overflow = 'visible';
            }
        }
    },
    mounted() {
        document.addEventListener('click', this.handleClickOutside);
    },
    beforeUnmount() {
        document.removeEventListener('click', this.handleClickOutside);
    },
    methods: {
    handleClickOutside(event) {
        if (!this.$refs.elementToDetectOutsideClick.contains(event.target)) {
            this.openReason = false;
        }
    },
    },
}
</script>

<template>
    <div class="font-gilroy pb-[58px]">
        <h3 class="text-light-main dark:text-dark-main py-5 text-[32px] font-bold">Техподдержка</h3>
        <router-link to="/support/request" class="flex w-full items-center justify-between gap-3 bg-accent rounded-2xl p-4 mb-5">
            <span class="flex items-start gap-3 w-full">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2C16.714 2 19.0711 2 20.5355 3.46447C22 4.92893 22 7.28595 22 12C22 16.714 22 19.0711 20.5355 20.5355C19.0711 22 16.714 22 12 22C7.28595 22 4.92893 22 3.46447 20.5355C2 19.0711 2 16.714 2 12Z" stroke="white" stroke-width="1.5"/>
                    <path d="M2 13H5.16026C6.06543 13 6.51802 13 6.91584 13.183C7.31367 13.3659 7.60821 13.7096 8.19729 14.3968L8.80271 15.1032C9.39179 15.7904 9.68633 16.1341 10.0842 16.317C10.482 16.5 10.9346 16.5 11.8397 16.5H12.1603C13.0654 16.5 13.518 16.5 13.9158 16.317C14.3137 16.1341 14.6082 15.7904 15.1973 15.1032L15.8027 14.3968C16.3918 13.7096 16.6863 13.3659 17.0842 13.183C17.482 13 17.9346 13 18.8397 13H22" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
                </svg>
                <span class="flex flex-col gap-1 text-white-accent">
                    <span class="font-bold text-base leading-5">Мои заявки</span>
                    <span class="font-medium text-xs leading-[14px]">1 заявка</span>
                </span>
            </span>
            <span class="flex items-center flex-shrink-0 gap-3">
                <span class="w-[23px] h-[23px] rounded-full bg-attention flex items-center justify-center text-white-accent font-bold text-xs leading-[15px] pt-[1px]">1</span>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6 11.3337L9.33333 8.00033L6 4.66699" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </span>
        </router-link>
        <div class="w-[calc(100%_+_32px)] -ml-4 p-4 bg-light-overlay dark:bg-transparent rounded-3xl">
            <h4 class="text-light-main dark:text-dark-main font-bold text-xl leading-6 mb-4">Новая заявка</h4>
            <div class="relative z-[2]" ref="elementToDetectOutsideClick">
                <button
                    @click="openReason = !openReason"
                    class="w-full h-[54px] px-4 flex items-center justify-between bg-light-accend-inp dark:bg-dark-accend-inp"
                    :class="openReason ? 'rounded-[12px_12px_0_0]' : 'rounded-xl'"
                >
                    <span class="text-light-main dark:text-dark-main font-medium text-base leading-5">Причина обращения</span>
                    <svg :class="{'rotate-180': openReason}" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.3335 6.33301L7.66683 9.66634L11.0002 6.33301" stroke="#2F52F8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </button>
                <ul v-if="openReason" class="absolute left-0 top-[54px] w-full px-4 bg-light-overlay dark:bg-dark-overlay border border-light-accend-inp dark:border-dark-accend-inp rounded-[0_0_12px_12px]">
                    <li @click="reason = 'Заказ'" class="flex items-center justify-between gap-[10px] py-[18px]">
                        <p class="text-light-main dark:text-dark-main font-medium text-base leading-5">Заказ</p>
                        <div
                            class="flex-shrink-0 w-6 h-6 rounded-full flex items-center justify-center"
                            :class="reason == 'Заказ' ? 'bg-accent' : 'bg-light-gray-20'"
                        >
                            <span v-if="reason == 'Заказ'" class="w-2 h-2 block rounded-full dark:border dark:rounded-full dark:border-white bg-light-overlay dark:bg-dark-overlay"></span>
                        </div>
                    </li>
                    <li class="h-[1px] bg-light-gray-20 dark:border-dark-gray-20"></li>
                    <li @click="reason = 'Оплата'" class="flex items-center justify-between gap-[10px] py-[18px]">
                        <p class="text-light-main dark:text-dark-main font-medium text-base leading-5">Оплата</p>
                        <div
                            class="flex-shrink-0 w-6 h-6 rounded-full flex items-center justify-center"
                            :class="reason == 'Оплата' ? 'bg-accent' : 'bg-light-gray-20'"
                        >
                            <span v-if="reason == 'Оплата'" class="w-2 h-2 block rounded-ful dark:border dark:rounded-full dark:border-whitel bg-light-overlay dark:bg-dark-overlay"></span>
                        </div>
                    </li>
                    <li class="h-[1px] bg-light-gray-20 dark:border-dark-gray-20"></li>
                    <li @click="reason = 'Другое'" class="flex items-center justify-between gap-[10px] py-[18px]">
                        <p class="text-light-main dark:text-dark-main font-medium text-base leading-5">Другое</p>
                        <div
                            class="flex-shrink-0 w-6 h-6 rounded-full flex items-center justify-center"
                            :class="reason == 'Другое' ? 'bg-accent' : 'bg-light-gray-20'"
                        >
                            <span v-if="reason == 'Другое'" class="w-2 h-2 block rounded-ful dark:border dark:rounded-full dark:border-whitel bg-light-overlay dark:bg-dark-overlay"></span>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="relative z-[1] bg-light-gray-20 dark:bg-dark-gray-20 mt-4 rounded-xl overflow-hidden">
                <label 
                    class="absolute z-[-1] top-4 left-4 text-description font-medium"
                    :class="msg.length ? 'text-[12px]' : 'text-[16px]'"
                    ref="lbl"
                >Текст обращения</label>
                <textarea @blur="msg.length == '' ? $refs.lbl.style.fontSize = '16px' : ''" @focus="$refs.lbl.style.fontSize = '12px'" v-model="msg" class="w-full h-[200px] py-[30px] px-4 outline-none caret-accent bg-transparent resize-none text-light-main dark:text-dark-main font-medium text-base leading-5"></textarea>
            </div>
        </div>
        <div class="fixed bottom-[56px] left-0 w-full z-[1]">
            <div class="container">
                <div class="w-[calc(100%_+_32px)] -ml-4 p-4 bg-light-overlay dark:bg-dark-overlay rounded-[24px_24px_0_0] border-b border-b-light-gray-20 dark:border-b-dark-gray-20">
                    <Btn @click="modal = true" :disabled="!msg.length">Отправить заявку</Btn>
                </div>
            </div>
        </div>

        <div v-if="modal" class="fixed top-0 left-0 w-full h-full z-[2] flex items-end pb-[72px]">
            <div class="absolute left-0 top-0 w-full h-full bg-black opacity-50 dark:opacity-90"></div>
            <div class="relative w-full left-0">
                <div class="container bg-light-overlay dark:bg-dark-overlay rounded-[20px] p-4">
                    <h3 class="text-light-main dark:text-dark-main font-bold text-[32px] leading-[39px] mb-3">Заявка отправлена</h3>
                    <p class="text-light-main dark:text-dark-main font-medium text-base leading-5 mb-8">Теперь ваша заявка находится в обработке. Время обработки - до двух часов. Ожидайте уведомления от бота.</p>
                    <Btn @click="modal = false; msg = ''" class="mb-3">Хорошо</Btn>
                    <Btn @click="$router.push({name: 'home'})" theme="light">На главный экран</Btn>
                </div>
            </div>
        </div>
    </div>
</template>
