<script>
import Header from '@/components/Header.vue'
import Nav from '@/components/Nav.vue'

export default {
  components: {
    Header,
    Nav,
  },
  data () {
    return {
      isDarkMode: true,
    }
  },
  created () {
    document.documentElement.classList.toggle('dark', this.isDarkMode);
  },
  computed: {
    hideNav () {
      if (
        this.$route.name == 'filter' ||
        this.$route.name == 'profile-address' ||
        this.$route.name == 'profile-info'||
        this.$route.name == 'profile-transaction' ||
        this.$route.name == 'order-unpaid' ||
        this.$route.name == 'order-id' ||
        this.$route.name == 'order-story' ||
        this.$route.name == 'order-payment' ||
        this.$route.name == 'order-created' ||
        this.$route.name == 'add-address' ||
        this.$route.name == 'about' ||
        this.$route.name == 'support-request-id'
      ) {
        return false;
      }
      return true
    }
  }
}
</script>


<template>
  <div class="w-full h-[100svh] overflow-x-hidden dark:bg-dark-overlay font-sf-pro font-normal">
    <Header />
    <div
      class="relative z-[1] bg-light-bg dark:bg-dark-bg rounded-[24px_24px_0_0] min-h-[calc(100svh_-_117.5px)]"
      :class="{
        'pb-[72px]': hideNav
      }"
    >
      <div class="container">
        <router-view/>
      </div>
    </div>
    <Nav v-if="hideNav" />
  </div>
</template>
