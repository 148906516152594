<script>
export default {
    name: "Support request",
    data () {
        return {}
    }
}
</script>

<template>
    <div class="font-gilroy">
        <div class="flex items-center py-[29px] relative">
            <router-link to="/support" class="rounded-xl bg-[#C5C3DC33] w-[40px] h-[40px] flex items-center justify-center absolute left-0">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path class="dark:stroke-dark-main" d="M14 7L9 12L14 17" stroke="#131313" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </router-link>
            <h3 class="font-gilroy dark:text-dark-main font-bold text-[20px] leading-[24px] w-full text-center">
                Мои заявки
            </h3>
        </div>
        <ul class="flex flex-col gap-2">
            <li v-for="i in 3" :key="i">
                <router-link to="/support/request/3124314234" class="block p-4 bg-light-overlay dark:bg-transparent rounded-[20px]">
                    <span class="flex items-center justify-between mb-3">
                        <span class="text-description text-xs leading-[]">29.07.2024</span>
                        <span class="bg-accent rounded-[20px] py-1 px-2 text-light-overlay dark:text-dark-overlay font-bold text-xs leading-[15px]">В обработке</span>
                    </span>
                    <span class="block text-light-main dark:text-dark-main font-bold text-base leading-5 mb-1">Вопрос по заказу <span class="inline text-accent">№{{i}}</span></span>
                    <span class="text-description font-medium text-base leading-5 block truncate">Ах какой замечательный заказ пришел! Я аж ахуел сперва что так быстро это было!</span>
                </router-link>
            </li>
        </ul>
    </div>
</template>