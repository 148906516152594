<script>
export default {
    name: "Order story"
}
</script>

<template>
    <div class="w-[calc(100%_+_32px)] -ml-4">
        <div class="flex items-center gap-[40px] py-[20px] px-[16px] w-full relative">
            <h3 class="font-gilroy dark:text-dark-main font-bold text-[20px] leading-[24px] text-center w-full">
                История заказа
            </h3>
            <button @click="$router.go(-1)" class="rounded-xl bg-[#C5C3DC33] w-[40px] absolute right-[16px] h-[40px] flex items-center justify-center">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path class="dark:stroke-dark-main" d="M17 7.00004L7.00004 17M7 7L17 17" stroke="#131313" stroke-width="1.5" stroke-linecap="round"/>
                </svg>
            </button>
        </div>
        <div class="bg-light-overlay dark:bg-transparent rounded-[24px_24px_0_0] px-[16px] py-[20px] flex flex-col gap-[8px] min-h-[calc(100svh_-_181.5px)]">
            <div class="flex items-start justify-between">
                <div class="flex items-start gap-[12px]">
                    <div class="w-[14px] h-[14px] bg-accent rounded-full translate-y-[2px] relative">
                        <div class="absolute w-[1px] bg-accent h-[52px] left-[6.70px]"></div>
                    </div>
                    <h6 class="font-gilroy text-base leading-[20px] text-accent font-bold">
                        Заказ создан
                    </h6>
                </div>
                <div class="flex flex-col gap-[4px] text-right">
                    <h5 class="font-gilroy text-base leading-[20px] font-medium text-light-main dark:text-dark-main">
                        09.10.2023
                    </h5>
                    <span class="font-gilroy text-base leading-[20px] font-medium text-description">
                        11:20:05
                    </span>
                </div>
            </div>
            <div class="flex items-start justify-between">
                <div class="flex items-start gap-[12px]">
                    <div class="w-[14px] h-[14px] bg-accent rounded-full translate-y-[2px] relative">
                        <div class="absolute w-[1px] bg-accent h-[52px] left-[6.70px]"></div>
                    </div>
                    <h6 class="font-gilroy text-base leading-[20px] text-accent font-bold">
                        Оплачен
                    </h6>
                </div>
                <div class="flex flex-col gap-[4px] text-right">
                    <h5 class="font-gilroy text-base leading-[20px] font-medium text-light-main dark:text-dark-main">
                        12.10.2023
                    </h5>
                    <span class="font-gilroy text-base leading-[20px] font-medium text-description">
                        23:20:05
                    </span>
                </div>
            </div>
            <div class="flex items-start justify-between">
                <div class="flex items-start gap-[12px]">
                    <div class="w-[14px] h-[14px] bg-accent rounded-full translate-y-[2px] relative">
                        <div class="absolute w-[1px] bg-accent h-[52px] left-[6.70px]"></div>
                    </div>
                    <h6 class="font-gilroy text-base leading-[20px] text-accent font-bold">
                        Отправлен
                    </h6>
                </div>
                <div class="flex flex-col gap-[4px] text-right">
                    <h5 class="font-gilroy text-base leading-[20px] font-medium text-light-main dark:text-dark-main">
                        13.10.2023
                    </h5>
                    <span class="font-gilroy text-base leading-[20px] font-medium text-description">
                        12:32:34
                    </span>
                </div>
            </div>
            <div class="flex items-start justify-between">
                <div class="flex items-start gap-[12px]">
                    <div class="w-[14px] h-[14px] bg-accent rounded-full translate-y-[2px] relative">
                        <div class="absolute w-[1px] bg-accent h-[52px] left-[6.70px]"></div>
                    </div>
                    <h6 class="font-gilroy text-base leading-[20px] text-accent font-bold">
                        Готов к выдаче
                    </h6>
                </div>
                <div class="flex flex-col gap-[4px] text-right">
                    <h5 class="font-gilroy text-base leading-[20px] font-medium text-light-main dark:text-dark-main h-[20px]">
                        15.10.2023
                    </h5>
                    <span class="font-gilroy text-base leading-[20px] font-medium text-description h-[20px]">
                        15:32:54
                    </span>
                </div>
            </div>
            <div class="flex items-start justify-between">
                <div class="flex items-start gap-[12px]">
                    <div class="w-[14px] h-[14px] bg-accent rounded-full translate-y-[2px] relative">
                    </div>
                    <h6 class="font-gilroy text-base leading-[20px] text-accent font-bold">
                        Выдан
                    </h6>
                </div>
                <div class="flex flex-col gap-[4px] text-right">
                    <h5 class="font-gilroy text-base leading-[20px] font-medium text-light-main dark:text-dark-main h-[20px]">
                        15.10.2023
                    </h5>
                    <span class="font-gilroy text-base leading-[20px] font-medium text-description h-[20px]">
                        18:34:23
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>
